.wrapper {
  align-items: center;
  background: var(--c-red-tertiary);
  border-radius: var(--radius);
  display: flex;
  gap: var(--pad);
  padding: var(--pad);
}

.icon {
  color: var(--c-secondary);
  transform: translate(0, 2px);
  width: 24px;
}
