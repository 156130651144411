/* Color Properties */
#storybook-root,
:root {
  --c-blue: #1598dc;
  --c-blue-secondary: #1598dccc;
  --c-green: #54bf2f;
  --c-red: #dc1919;
  --c-red-secondary: #dc1919cc;
  --c-red-tertiary: #dc191955;
  --c-orange: #db781b;
  --c-bg: #fff;
  --c-bg-secondary: rgba(255, 255, 255, 0.85);
  --c-on-color: #fff;
  --c-on-color-secondary: #fffa;
  --c-primary: #000;
  --c-secondary: rgba(0, 0, 0, 0.8);
  --c-tertiary: rgba(0, 0, 0, 0.44);
  --c-quatenary: rgba(0, 0, 0, 0.05);
}

@media (prefers-color-scheme: dark) {
  #storybook-root,
  :root {
    --c-bg: #222;
    --c-bg-secondary: #222e;
    --c-primary: #fff;
    --c-secondary: rgba(255, 255, 255, 0.8);
    --c-tertiary: rgba(255, 255, 255, 0.44);
    --c-quatenary: rgba(255, 255, 255, 0.05);
  }
}

/* Shared UI Properties */
#storybook-root,
:root {
  --backdrop-blur: blur(3px);
  --bold: 700;
  --c-text: var(--c-secondary);
  --c-text-bold: var(--c-primary);
  --c-text-light: var(--c-tertiary);
  --drop-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.15);
  --gutter: 40px;
  --pad: 16px;
  --pad-l: 24px;
  --pad-s: 8px;
  --pad-xs: 4px;
  --radius: 6px;
  --touch-target: 44px;
  --transition: all 0.2s cubic-bezier(0, 0, 0.5, 1);
  --z-above-content: 9;
  --z-above-most: 999;
  --z-above-everything: 99999;
}

@media (min-width: 1300px) {
  #storybook-root,
  :root {
    --gutter: 60px;
  }
}

* {
  font: inherit;
  color: inherit;
}

#storybook-root,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--c-bg);
  color: var(--c-text);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  overscroll-behavior: none;
}

code {
  background: var(--c-bg-secondary);
  display: inline-block;
  color: var(--c-orange);
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
.h1 {
  color: var(--c-text-bold);
  font-size: 32px;
  font-weight: var(--bold);
  line-height: 36px;
}
h1:first-child,
.h1:first-child {
  margin-bottom: 0;
}
h1:first-child + p,
.h1:first-child + p,
h1:first-child + .text,
.h1:first-child + .text {
  margin-top: 8px;
}

h2,
.h2 {
  color: var(--c-text-bold);
  font-size: 24px;
  font-weight: var(--bold);
  line-height: 32px;
}

h3,
.h3 {
  color: var(--c-text-bold);
  font-size: 18px;
  font-weight: var(--bold);
  line-height: 24px;
}

h4,
.h4 {
  color: var(--c-text-bold);
  font-size: 16px;
  font-weight: var(--bold);
  line-height: 24px;
}

h5,
.h5 {
  color: var(--c-text);
  font-size: 16px;
  font-weight: var(--bold);
  line-height: 24px;
}

h6,
.h6 {
  color: var(--c-text);
  font-size: 13px;
  font-weight: var(--bold);
  line-height: 16px;
}

h1:first-child,
.h1:first-child,
h2:first-child,
.h2:first-child,
h3:first-child,
.h3:first-child,
h4:first-child,
.h4:first-child,
h5:first-child,
.h5:first-child,
h6:first-child,
.h6:first-child {
  margin-top: 0;
}

h1:last-child,
.h1:last-child,
h2:last-child,
.h2:last-child,
h3:last-child,
.h3:last-child,
h4:last-child,
.h4:last-child,
h5:last-child,
.h5:last-child,
h6:last-child,
.h6:last-child {
  margin-bottom: 0;
}

.text--noMargin {
  margin: 0;
}

.text--s {
  font-size: 13px;
  line-height: 16px;
}

.text--light {
  color: var(--c-text-light);
}

.text--center {
  text-align: center;
}

.text--trailing {
  text-align: right;
}

.text--truncated {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

strong,
b,
.text--bold {
  font-weight: var(--bold);
}

em,
.text--em {
  font-style: italic;
}

.text,
p {
  font-size: 16px;
  color: var(--c-text);
  margin: 16px 0;
}
.text:first-child,
p:first-child {
  margin-top: 0;
}
.text:last-child,
p:last-child {
  margin-bottom: 0;
}

@media (min-width: 800px) {
  .u--hidden-multicolumn {
    display: none;
  }
}

*:focus:not(.focus-visible) {
  outline: 0;
}

.firebase-emulator-warning {
  pointer-events: none;
  opacity: 0.2;
}
