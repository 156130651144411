.wrapper {
  box-sizing: border-box;
  width: 100%;
}

.content {
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.nav {
  -webkit-overflow-scrolling: touch;
  background-color: var(--c-bg);
  box-sizing: border-box;
  height: 100%;
  left: 0;
  opacity: 0;
  overflow-y: auto;
  pointer-events: none;
  position: fixed;
  top: 0;
  transform: translateX(-33%);
  transition: var(--transition);
  transition-property: blur transform;
  width: 100%;
  z-index: var(--z-above-content);
}
.navIsOpen {
  transform: translateX(0);
  opacity: 1;
  pointer-events: all;
}
.nav:focus {
  outline: 0;
}

@supports (backdrop-filter: var(--backdrop-blur)) {
  .nav {
    background-color: var(--c-bg-secondary);
    backdrop-filter: var(--backdrop-blur);
  }
}

@media (min-width: 800px) {
  .content {
    padding: 0;
  }

  .nav {
    background: transparent;
    backdrop-filter: unset;
    height: 100vh;
    opacity: 1;
    transform: none;
    padding: 0;
    position: sticky;
    pointer-events: all;
    transition: none;
    z-index: 1;
  }

  .wrapper {
    display: grid;
    grid-template-columns: minmax(260px, 320px) minmax(320px, 900px);
  }
}

@media (min-width: 1200px) {
  .wrapper {
    grid-template-columns: minmax(320px, 460px) minmax(66%, 100%);
  }
}
