@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.wrapper {
  line-height: 0;
  min-width: 1em;
  max-width: 48px;
  padding: 0;
}
.wrapper-enter {
  opacity: 0;
}
.wrapper-enter-active {
  opacity: 1;
  transition: opacity 0.2s;
}
.wrapper-exit {
  opacity: 1;
}
.wrapper-exit-active {
  opacity: 0;
  transition: opacity 0.2s;
}

.icon {
  animation: spin 1s ease-in-out infinite;
  color: var(--c-tertiary);
}
