.button {
  appearance: none;
  border-radius: var(--radius);
  border: 0;
  cursor: pointer;
  margin: 0;
  transition: var(--transition);
  transition-property: background-color;
}

/* Sizes */
.buttonM {
  padding: var(--pad);
}

.buttonS {
  padding: var(--pad-s) var(--pad);
}

/* Types */
.buttonDanger {
  background: var(--c-red);
  color: var(--c-on-color);
  font-weight: var(--bold);
}
.buttonDanger:hover {
  background: var(--c-red-secondary);
}

.buttonDisabled {
  cursor: not-allowed;
  background: var(--c-tertiary);
  color: var(--c-on-color-secondary);
  font-weight: var(--bold);
}

.buttonPrimary {
  background: var(--c-blue);
  color: var(--c-on-color);
  font-weight: var(--bold);
}
.buttonPrimary:hover {
  background: var(--c-blue-secondary);
}

.buttonSecondary {
  background: transparent;
  color: var(--c-blue);
}
.buttonSecondary:hover {
  background: var(--c-quatenary);
}

.buttonWarning {
  background: transparent;
  color: var(--c-red);
}
.buttonWarning:hover {
  background: var(--c-quatenary);
}

/* Modifiers */
.buttonAlignLeading {
  text-align: left;
}

.buttonFullWidth {
  box-sizing: border-box;
  width: 100%;
}
