.wrapper {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  border-radius: var(--radius);
  box-sizing: border-box;
  cursor: pointer;
  padding: var(--pad-s) var(--pad);
  text-align: left;
  transition: var(--transition);
  transition-property: background-color;
  overflow: hidden;
  width: 100%;
}
.wrapper:hover {
  background-color: var(--c-quatenary);
}
.wrapperIsActive {
  background-color: var(--c-quatenary);
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}
