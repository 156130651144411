.wrapper {
  align-items: center;
  display: flex;
  gap: var(--pad-s);
  justify-content: space-between;
  min-height: 44px;
}

.trailing,
.center,
.leading {
  align-items: center;
  display: flex;
  gap: var(--pad-s);
  min-height: 44px;
}

.center {
  justify-content: center;
}

.trailing {
  justify-content: right;
}
