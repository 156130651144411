.wrapper {
  -webkit-appearance: none;
  background: transparent;
  box-sizing: border-box;
  border: 0;
  color: var(--c-tertiary);
  cursor: pointer;
  display: inline-block;
  height: var(--touch-target);
  transition: color 0.2s ease-out;
  position: relative;
  width: var(--touch-target);
}
.wrapper:hover {
  color: var(--c-primary);
}

.wrapper > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 16px;
  width: 16px;
  transform: translate(-50%, -50%);
}

.wrapperOffsetLeading {
  margin-left: -14px;
}

.wrapperOffsetTrailing {
  margin-right: -14px;
}

@media (min-width: 800px) {
  .wrapperHiddenLg {
    display: none;
  }
}

.isActive {
  color: var(--c-blue);
}
