.wrapper {
  display: grid;
  gap: var(--pad);
  overflow: hidden;
  width: 100%;
}

.gapL {
  gap: var(--pad-l);
}

.gapS {
  gap: var(--pad-s);
}

.gapXs {
  gap: var(--pad-xs);
}
