.wrapper {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.menu {
  background: var(--c-bg);
  border-radius: var(--radius);
  box-shadow: var(--drop-shadow);
  display: flex;
  flex-direction: column;
  gap: var(--pad);
  min-width: 200px;
  opacity: 0;
  padding: var(--pad) 0;
  pointer-events: none;
  position: absolute;
  transform: translate(0, -50px) scaleY(0.8);
  transition: var(--transition);
  transition-property: opacity, transform;
  z-index: var(--z-above-most);
  top: 100%;
}
@supports (backdrop-filter: blur(var(--backdrop-blur))) {
  .menu {
    backdrop-filter: blur(var(--backdrop-blur));
    background: var(--c-bg-secondary);
  }
}
.menuLeadingAnchor {
  left: 0;
}
.menuTrailingAnchor {
  right: 0;
}
.menuOpen {
  opacity: 1;
  pointer-events: all;
  transform: translate(0, var(--pad-xs));
}
.menuNoPadBottom {
  padding-bottom: 0;
}

@media (prefers-reduced-motion) {
  .menu {
    transition-property: opacity;
  }
}

.header {
  padding: 0 var(--pad);
}
